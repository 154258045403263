<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Under Maintenance 🛠
        </h2>

        <p class="mb-1">
          Our website is currently undergoing maintenance and may be temporarily unavailable. We're working to restore full service as soon as possible.
          Thank you for your patience!
        </p>
        <!-- <br>
        <p class="mb-3">Thank you for your patience!</p> -->
        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Under maintenance page"
        />
      </div>
    </div>
  </div>
<!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */

export default {
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  computed: {
    imgUrl() {
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
